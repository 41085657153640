import { Flex, SystemProps } from '@storyofams/react-ui';
import { includes } from 'lodash';
import dynamic from 'next/dynamic';
import { LegacyContainer } from '~components';
import { Breadcrumb } from '~components/common/Breadcrumbs';

import type {
  ContactForm as ContactFormType,
  Faq as FaqType,
  GallerySection as GallerySectionType,
  ImageSection as ImageSectionType,
  Quote as QuoteType,
  TextImage as TextImageType,
  TextVideo as TextVideoType,
  TitleText as TitleTextType,
  VideoSection as VideoSectionType,
  Intro as IntroType,
  ProductSeries as ProductSeriesType,
  DownloadSection as DownloadSectionType,
  HeroSection as HeroSectionType,
  ContactSection as ContactSectionType,
  CasesSection as CasesSectionType,
  ScrollBarSection as ScrollBarType,
  HeaderSmall as HeaderSmallType,
  ImageGrid as ImageGridType,
  Usps as UspsType,
  Logos as LogosType,
  Applications as ApplicationsType,
  TitleSection as TitleType,
  Cta as CtaType,
  Materials as MaterialsType,
  ContactCard as ContactCardType,
  Products as ProductsType,
  References as ReferencesType,
  TwoColumnText as TwoColumnTextType,
  ApplicationImagery as ApplicationImageryType,
  ProductSpecifications as ProductSpecificationsType,
  SpecificationsTable as SpecificationsTableType,
  ProductSchematics as ProductSchematicsType,
  ProductImageGrid as ProductImageGridType,
} from './sections';
import { useRouter } from 'next/router';

const ContactForm = dynamic(() =>
  import('./sections/ContactForm').then((mod) => mod.ContactForm),
) as typeof ContactFormType;
const Faq = dynamic(() =>
  import('./sections/Faq').then((mod) => mod.Faq),
) as typeof FaqType;
const ImageSection = dynamic(() =>
  import('./sections/ImageSection').then((mod) => mod.ImageSection),
) as typeof ImageSectionType;
const Quote = dynamic(() =>
  import('./sections/Quote').then((mod) => mod.Quote),
) as typeof QuoteType;
const TextImage = dynamic(() =>
  import('./sections/TextImage').then((mod) => mod.TextImage),
) as typeof TextImageType;
const TextVideo = dynamic(() =>
  import('./sections/TextVideo').then((mod) => mod.TextVideo),
) as typeof TextVideoType;
const TitleText = dynamic(() =>
  import('./sections/TitleText').then((mod) => mod.TitleText),
) as typeof TitleTextType;
const VideoSection = dynamic(() =>
  import('./sections/VideoSection').then((mod) => mod.VideoSection),
) as typeof VideoSectionType;
const Intro = dynamic(() =>
  import('./sections/Intro').then((mod) => mod.Intro),
) as typeof IntroType;
const Logos = dynamic(() =>
  import('./sections/Logos').then((mod) => mod.Logos),
) as typeof LogosType;
const ProductSeries = dynamic(() =>
  import('./sections/ProductSeries').then((mod) => mod.ProductSeries),
) as typeof ProductSeriesType;
const DownloadSection = dynamic(() =>
  import('./sections/DownloadSection').then((mod) => mod.DownloadSection),
) as typeof DownloadSectionType;
const HeroSection = dynamic(() =>
  import('./sections/HeroSection').then((mod) => mod.HeroSection),
) as typeof HeroSectionType;
const ContactSection = dynamic(() =>
  import('./sections/ContactSection').then((mod) => mod.ContactSection),
) as typeof ContactSectionType;
const CasesSection = dynamic(() =>
  import('./sections/LinkSection').then((mod) => mod.CasesSection),
) as typeof CasesSectionType;
const ScrollBar = dynamic(() =>
  import('./sections/ScrollBar').then((mod) => mod.ScrollBarSection),
) as typeof ScrollBarType;
const HeaderSmall = dynamic(() =>
  import('./sections/HeaderSmall').then((mod) => mod.HeaderSmall),
) as typeof HeaderSmallType;
const ImageGrid = dynamic(() =>
  import('./sections/ImageGrid').then((mod) => mod.ImageGrid),
) as typeof ImageGridType;
const GallerySection = dynamic(() =>
  import('./sections/GallerySection').then((mod) => mod.GallerySection),
) as typeof GallerySectionType;
const Usps = dynamic(() =>
  import('./sections/Usps').then((mod) => mod.Usps),
) as typeof UspsType;
const Applications = dynamic(() =>
  import('./sections/Applications').then((mod) => mod.Applications),
) as typeof ApplicationsType;
const Title = dynamic(() =>
  import('./sections/Title').then((mod) => mod.TitleSection),
) as typeof TitleType;
const Cta = dynamic(() =>
  import('./sections/Cta').then((mod) => mod.Cta),
) as typeof CtaType;
const Materials = dynamic(() =>
  import('./sections/Materials').then((mod) => mod.Materials),
) as typeof MaterialsType;
const ContactCard = dynamic(() =>
  import('./sections/ContactCard').then((mod) => mod.ContactCard),
) as typeof ContactCardType;
const Products = dynamic(() =>
  import('./sections/Products').then((mod) => mod.Products),
) as typeof ProductsType;
const References = dynamic(() =>
  import('./sections/References').then((mod) => mod.References),
) as typeof ReferencesType;
const TwoColumnText = dynamic(() =>
  import('./sections/TwoColumnText').then((mod) => mod.TwoColumnText),
) as typeof TwoColumnTextType;
const ApplicationImagery = dynamic(() =>
  import('./sections/ApplicationImagery').then((mod) => mod.ApplicationImagery),
) as typeof ApplicationImageryType;
const ProductSpecifications = dynamic(() =>
  import('./sections/ProductSpecifications').then((mod) => mod.ProductSpecifications),
) as typeof ProductSpecificationsType;
const SpecificationsTable = dynamic(() =>
  import('./sections/SpecificationsTable').then((mod) => mod.SpecificationsTable),
) as typeof SpecificationsTableType;
const ProductSchematics = dynamic(() =>
  import('./sections/ProductSchematics').then((mod) => mod.ProductSchematics),
) as typeof ProductSchematicsType;
const ProductImageGrid = dynamic(() =>
  import('./sections/ProductImageGrid').then((mod) => mod.ProductImageGrid),
) as typeof ProductImageGridType;


const Components = {
  // applications: ScrollBar,
  contact_form: ContactForm,
  ContactForm: ContactForm,
  faq_dynamic: Faq,
  image: ImageSection,
  quote: Quote,
  rich_text: TextImage,
  text_image: TextImage,
  text_video: TextVideo,
  title_description: TitleText,
  title_text: TitleText,
  video: VideoSection,
  video_dynamic: VideoSection,
  intro: Intro,
  logos: Logos,
  product_series: ProductSeries,
  downloads: DownloadSection,
  'hero-large': HeroSection,
  'hero-medium': HeroSection,
  contact: ContactSection,
  cases: CasesSection,
  scroll_bar: ScrollBar,
  headerSmall: HeaderSmall,
  image_grid: ImageGrid,
  gallery: GallerySection,
  usps: Usps,
  applications: Applications,
  title: Title,
  cta: Cta,
  materials: Materials,
  contact_card: ContactCard,
  products: Products,
  references: References,
  two_column_text: TwoColumnText,
  applicationImagery: ApplicationImagery,
  productSpecifications: ProductSpecifications,
  specificationsTable: SpecificationsTable,
  productSchematics: ProductSchematics,
  productImageGrid: ProductImageGrid,
};

export interface DynamicSectionsProps extends SystemProps {
  // termsAndConditions?: TermsandconditionsComponent;
  content?: any;
  withBreadCrumbs?: boolean;
  name?: string;
  compatibleSeries?: Record<string, any>[];
}

export const DynamicSections = ({
  content,
  withBreadCrumbs,
  name,
  ...props
}: DynamicSectionsProps) => {
  const { locale } = useRouter();
  const firstSection = content?.sections?.[0];
  const isFirstSectionImg =
    includes(
      ['hero-large', 'image', 'hero-medium', 'headerSmall'],
      firstSection?.component,
    ) ||
    (firstSection?.component === 'title' &&
      firstSection?.bg &&
      firstSection?.bg !== 'white');

  return content?.sections?.length
    ? content?.sections?.filter((section) => {
      return !section.inactive_regions?.includes(locale.split("-")[1])
    })?.map((section, i) => {
        const Component = Components[section?.component];
        if (
          !!section &&
          typeof Component !== 'undefined'
        ) {
          return (
            <Flex
              width="100%"
              justifyContent={"start"}
              flexDirection={isFirstSectionImg ? 'column' : 'column-reverse'}
              alignSelf={content?.alignStart === true ? "flex-start" : 'initial'}
              key={i}
            >
              <Component
                key={section?._uid}
                content={section}
                divider={false}
                mb={0}
                first={i === 0 && (section?.title || section?.quote)}
                last={
                  i === content?.sections.length - 1 &&
                  section?.component === 'contact_form'
                }
                {...props}
              />

              {i === 0 && withBreadCrumbs && (
                <LegacyContainer pt={isFirstSectionImg ? [3] : [5, 6]} pb={0}>
                  <Breadcrumb links={[{ title: name || 'Home', href: '/' }]} />
                </LegacyContainer>
              )}
            </Flex>
          );
        }

        return null;
      })
    : null;
};

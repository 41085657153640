import React from 'react';
import {
  Box,
  Flex,
  Icon,
  Button,
  SystemProps,
  css,
} from '@storyofams/react-ui';
import { Home } from '~components/common/Icons';
import { Text } from '~components/common/Text';

export interface BreadcrumbProps extends SystemProps {
  links: Array<{ title: string; href?: string }>;
}

export const Breadcrumb = ({ links, ...props }: BreadcrumbProps) => (
  <Box display="flex" flexDirection="row" alignItems="center" {...props}>
    <Button
      as="a"
      to="/"
      aria-current="step"
      color="black"
      variant="link"
      title="home"
    >
      <Icon icon={Home} color="black" fontSize={1.75} mr={0.5} />
    </Button>

    {links.map(({ title, href }, index) => (
      <Flex key={`${title}-${index}`} alignItems="center">
        <Text mr={0.5} fontSize={1.5} lineHeight="130%">
          /
        </Text>
        {index === links.length - 1 ? (
          <Text variant="s" fontSize={1.5} aria-current="step" title={title}>
            {title}
          </Text>
        ) : (
          <Button
            to={href}
            title={title}
            aria-current="step"
            variant="link"
            css={css({
              fontSize: 1.5,
              color: 'grey900',
              mr: 0.5,
            })}
          >
            {title}
          </Button>
        )}
      </Flex>
    ))}
  </Box>
);

import { storyblokEditable } from '@storyblok/react';
import { Stack, SystemProps } from '@storyofams/react-ui';
import { WithStoryProps } from '@storyofams/storyblok-toolkit';

import { Seo, LegacyContainer, Heading } from '~components';
import { DynamicSections } from './DynamicSections';

export interface DynamicPageProps extends WithStoryProps, SystemProps {
}

export const DynamicPage = ({ story, ...props }: DynamicPageProps) => {
  const { content } = story;

  return (
    <>
      <Seo story={story} />
      <Stack
        alignItems="center"
        width="100%"
        flexDirection="column"
        space={[8, 8, 10]}
        // no margin top if image first
        mt={content?.sections?.[0]?.component === 'image' ? 0 : 6}
        // no margin bottom if form last
        mb={
          content?.sections?.[
            content?.sections?.length - 1
          ]?.component?.includes('form')
            ? 0
            : [8, 10]
        }
        {...storyblokEditable(content)}
      >
        {!!content?.sections?.length ? (
          <DynamicSections content={content} {...props} />
        ) : (
          <LegacyContainer pt={6} textAlign="center">
            <Heading as="h1" variant="h1">
              Let the story unfold!
            </Heading>
          </LegacyContainer>
        )}
      </Stack>
    </>
  );
};

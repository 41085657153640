import { ReactNode } from 'react';
import { Box, SystemProps } from '@storyofams/react-ui';

import { includes } from 'lodash';

type DynamicSectionContainerProps = {
  children: ReactNode;
  space?: any;
  component?: string;
  isFullWidth?: boolean;
  isFullWidthMobile?: boolean;
  withOverflowHidden?: boolean;
  id?: string;
} & SystemProps;

export const DynamicSectionContainer = ({
  children,
  bg,
  component,
  isFullWidthMobile,
  isFullWidth,
  ...props
}: DynamicSectionContainerProps) => {
  return (
    <Box
      as="section"
      width="100%"
      bg={bg ? bg : 'white'}
      {...(includes(bg, 'waves')
        ? {
            backgroundImage: `url(/images/${bg}.svg)`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }
        : {})}
      {...props}
    >
      {children}
    </Box>
  );
};

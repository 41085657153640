import { SystemProps, Box, css } from '@storyofams/react-ui';
import {
  NODE_HEADING,
  NODE_PARAGRAPH,
  NODE_OL,
  NODE_UL,
} from 'storyblok-rich-text-react-renderer';
import { Heading, RichText as RichTextComponent } from '~components';

type RichTextProps = {
  text: any;
} & SystemProps;

export const RichText = ({ text, ...props }: RichTextProps) => (
  <RichTextComponent
    text={text}
    nodeResolvers={{
      [NODE_HEADING]: (children, { level }) => (
        <Heading as={`h${level}` as any} variant="h4" pb={[2]}>
          {children}
        </Heading>
      ),
      [NODE_PARAGRAPH]: (children) => (
        <Box
          pb={[2, 2, 3]}
          as="p"
          fontSize={[1.75, 1.75, 2.25]}
          lineHeight={['high', 'high', '160%']}
        >
          {children}
        </Box>
      ),
      [NODE_OL]: (children) => (
        <Box
          as="ol"
          pl={2}
          pb={[1, 1, 2]}
          css={css({ listStyleType: 'number', li: { pb: 1 }, p: { pb: 0 } })}
        >
          {children}
        </Box>
      ),
      [NODE_UL]: (children) => (
        <Box
          as="ul"
          pl={2}
          pb={[1, 1, 2]}
          css={css({ listStyleType: 'disc', li: { pb: 1 }, p: { pb: 0 } })}
        >
          {children}
        </Box>
      ),
    }}
    {...props}
  />
);

import { SystemProps } from '@storyofams/react-ui';
import dynamic from 'next/dynamic';

import type {
  LinkItem as LinkItemType,
} from './components';
import { useRouter } from 'next/router';

const LinkItem = dynamic(() =>
  import('./components/LinkItem').then((mod) => mod.LinkItem),
) as typeof LinkItemType;


const Components = {
  linkItem: LinkItem,
};

export interface DynamicComponentProps extends SystemProps {
  content?: any;
  variant?: string;
}

export const DynamicComponents = ({
  content,
  ...props
}: DynamicComponentProps) => {
  const { locale } = useRouter();

  return content?.sections?.length
    ? content?.sections?.filter((section) => {
      return !section.inactive_regions?.includes(locale.split("-")[1])
    })?.map((section, i) => {
        const Component = Components[section?.component];
        if (
          !!section &&
          typeof Component !== 'undefined'
        ) {
          return (
              <Component
                key={section?._uid}
                content={section}
                {...props}
              />
          );
        }

        return null;
      })
    : null;
};
